.header {
  background-color: $dark-grey;
  color: $light-grey;
  display: flex;
  height: 100px;
  justify-content: space-between;
  padding: 10px 80px;

  .branding {
    display: flex;
    justify-content: flex-start;

    .logo {
      height: 200px;
    }

    .company-name {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .horizontal-nav,
  .hamburger-nav {
    max-width: 620px;
    text-decoration: none;
    width: 60%;

    ul {
      align-items: center;
      display: flex;
      justify-content: space-between;
      list-style-type: none;
    }

    a {
      color: $light-grey;
      text-decoration: none;
    }

    a:hover {
      color: $button-red;
    }
  }

  .hamburger-nav {
    align-items: center;
    background-color: $dark-grey;
    display: none;
    height: 100vh;
    justify-content: center;
    left: 0;
    max-width: unset;
    position: absolute;
    top: 0;
    width: 100vw;

    ul {
      flex-direction: column;
      padding: 0;

      li {
        padding: 20px;
      }
    }
  }

  .hamburger-icon {
    display: none;
    right: 50px;
    position: absolute;
    top: 30px;
    z-index: 20;

    span {
      background-color: $white;
      display: block;
      height: 5px;
      margin: 8px auto;
      width: 50px;
      -webkit-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }

    & .hamburger-active:nth-child(2) {
      opacity: 0;
    }

    & .hamburger-active:nth-child(1) {
      -webkit-transform: translateY(13px) rotate(45deg);
      -ms-transform: translateY(13px) rotate(45deg);
      -o-transform: translateY(13px) rotate(45deg);
      transform: translateY(13px) rotate(45deg);
    }

    & .hamburger-active:nth-child(3) {
      -webkit-transform: translateY(-13px) rotate(-45deg);
      -ms-transform: translateY(-13px) rotate(-45deg);
      -o-transform: translateY(-13px) rotate(-45deg);
      transform: translateY(-13px) rotate(-45deg);
    }
  }

  .hamburger-icon:hover {
    cursor: pointer;
  }
}

@media only screen and (max-width: $large-screen) {
  .header .horizontal-nav {
    display: none;
  }
  .header .hamburger-nav {
    display: flex;
  }
  .header .hamburger-icon {
    display: unset;
  }
}

@media only screen and (max-width: $small-screen) {
  .header {
    padding-left: 25px;

    .logo {
      display: none;
    }

    .hamburger-icon {
      right: 20px;
      top: 40px;
    }
  }
}
