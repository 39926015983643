.testimonials {
  background-color: $dark-red;
  display: flex;
  height: 250px;
  align-items: center;
  padding: 100px;

  .testimonial {
    display: flex;
    flex-direction: column;
    padding: 0 40px;

    .star {
      max-width: 20px;
    }

    p {
      color: $white;
      padding: 10px 0;
    }
  }

  button {
    background-color: transparent;

    .left-caret,
    .right-caret {
      width: 30px;
    }

    .left-caret {
      transform: rotate(180deg);
    }
  }
}

@media only screen and (max-width: $large-screen) {
  .testimonials {
    height: 400px;
    padding: unset;

    .testimonial {
      padding: 0 20px;
    }
  }
}

@media only screen and (max-width: $small-screen) {
  .testimonials {
    height: 580px;
    padding: 30px;

    button {
      display: none;
    }

    .testimonial {
      padding: 0;
    }
  }
}
