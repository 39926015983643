// colors
$white: #fff;
$dark-grey: #333;
$med-grey: #d8d8d8;
$light-grey: #eee;
$dark-red: #8e0000;
$button-red: #c62828;
$black: #000;
$border: #ccc;

// break points
$large-screen: 1000px;
$medium-screen: 800px;
$small-screen: 700px;
