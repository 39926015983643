.team {
  background-color: $light-grey;
  text-align: center;

  .profiles {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .vertical-rule {
    border-top: 1px solid $border;
    height: 1px;
    margin: 50px 0;
    width: 100%;
  }

  .motto {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-style: italic;
    margin: 0 15px;

    p {
      margin-left: 15px;
      width: 665px;
    }

    img {
      width: 200px;
    }
  }
}

@media only screen and (max-width: $small-screen) {
  .motto {
    flex-direction: column;
  }

  .team .motto p {
    margin: 40px 0 0 0;
    width: 100%;
  }
}
