.service-full {
  text-align: center;
  text-decoration: none;

  img {
    border-radius: 100%;
    border: 2px solid $dark-red;
    height: auto;
    margin-top: 30px;
    width: 245px;
  }

  p {
    color: $black;
  }
}
