.hero {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 100px;

  img {
    width: 50%;
  }

  .hero-text {
    display: flex;
    flex-direction: column;
    padding-right: 30px;

    h5 {
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: $large-screen) {
  .hero {
    flex-direction: column;
    text-align: center;

    img {
      width: 75%;
    }

    .hero-text {
      margin-bottom: 30px;
      padding-right: unset;
    }
  }
}

@media only screen and (max-width: $small-screen) {
  .hero {
    padding-top: 50px;

    img {
      height: 100%;
      object-fit: scale-down;
      width: 100%;
    }
  }
}
