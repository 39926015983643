.profile {
  text-align: center;
  width: 30%;
  min-width: 245px;

  img {
    border-radius: 100%;
    border: 2px solid $dark-red;
    height: auto;
    width: 245px;
  }

  p {
    margin: 20px 0;
  }
}

@media only screen and (max-width: $large-screen) {
  .profile {
    width: 100%;
  }
}
