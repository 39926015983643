.service-preview {
  margin-bottom: 50px;
  text-align: center;
  text-decoration: none;
  width: 30%;
  min-width: 245px;

  img {
    border-radius: 100%;
    border: 2px solid $dark-red;
    height: auto;
    width: 245px;
  }

  p {
    color: $black;
  }
}

.service-preview:hover {
  h4 {
    color: $button-red;
  }

  img {
    border-color: $button-red;
  }
}

@media only screen and (max-width: $large-screen) {
  .service-preview {
    width: 100%;
  }
}
