.contact {
  display: flex;
  justify-content: space-between;

  h3 {
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 20px;
  }

  .form,
  .location {
    width: 45%;
  }

  iframe.map {
    margin-bottom: 20px;
    width: 100%;
  }
}

@media only screen and (max-width: $medium-screen) {
  .contact {
    flex-direction: column;

    .form,
    .location {
      width: 100%;
    }

    iframe.map {
      margin-top: 20px;
    }
  }

  hr {
    display: none;
  }
}
