@import url("https://fonts.googleapis.com/css?family=Lato|Merriweather&display=swap");
@import "./App.scss";

html {
  margin: 0;
}

body {
  box-sizing: border-box;
  color: $black;
  font-family: "Lato";
  font-size: 16px;
  margin: 0;

  h2,
  h3,
  h4 {
    font-family: "Merriweather";
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  h1 {
    font-family: "Merriweather";
    font-size: 32px;
    margin-bottom: 0;
  }

  h2 {
    font-size: 36px;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 28px;
    margin-bottom: 50px;
  }

  h4 {
    color: $dark-red;
    font-size: 24px;
    margin: 30px 0;
  }

  h5 {
    font-size: 20px;
  }
}

a {
  color: $button-red;
  text-decoration: none;
}

.container {
  padding: 50px 80px 100px;
}

@media only screen and (max-width: $large-screen) {
  .container {
    padding: 50px 50px 100px;
  }
}

@media only screen and (max-width: $small-screen) {
  .container {
    padding: 50px 25px;
  }

  body {
    h1 {
      font-size: 26px;
    }

    h2 {
      font-size: 24px;
      margin-bottom: 30px;
    }

    h4 {
      font-size: 22px;
    }

    h5 {
      font-size: 18px;
    }
  }
}
