.services {
  background-color: $med-grey;
  text-align: center;

  .service-collection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .vertical-rule {
    border-top: 1px solid $black;
    height: 1px;
    margin: 50px 0;
    width: 100%;
  }
}
