button {
  background-color: $button-red;
  border: none;
  border-radius: 5px;
  color: $white;
  cursor: pointer;
  font-size: 18px;
  padding: 15px 25px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

button:focus {
  outline: none;
}
