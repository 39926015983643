.footer {
  align-items: center;
  background-color: $dark-grey;
  color: $light-grey;
  display: flex;
  flex-direction: column;
  padding: 30px 50px 50px;

  hr {
    width: calc(100% - 60px);
  }

  img {
    height: 70px;
  }
}

@media only screen and (max-width: $small-screen) {
  .footer p {
    margin-bottom: 30px;
    text-align: center;
  }
}
