.contact-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    input,
    textarea {
      font: 400 13.3333px "Lato";
    }
    input::placeholder,
    textarea::placeholder {
      color: $dark-red;
      font: 14px "Lato";
      text-transform: uppercase;
    }

    input::placeholder {
      transform: translateY(-8px);
    }

    .form-field {
      border: 1px solid $border;
      border-radius: 5px;
      box-sizing: border-box;
      font-size: 20px;
      height: 50px;
      margin: 5px 0;
      padding: 10px;
    }

    .name,
    .address,
    .comment {
      width: 100%;
    }
    .email,
    .phone {
      width: calc(50% - 5px);
    }
    .comment {
      height: 250px;
      resize: none;
    }
  }

  .submit {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .error {
      color: red;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: $medium-screen) {
  .form {
    width: 100%;

    .form-field {
      width: 100%;
    }
  }
}

@media only screen and (max-width: $small-screen) {
  .contact-form form .email,
  .contact-form form .phone {
    width: 100%;
  }
}
